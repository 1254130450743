<template>
  <SimpleGallery galleryID="carteles-gallery" :images="images" />
</template>

<script>
import SimpleGallery from './PhotoSwipe.vue';

export default {
  name: 'App',
  components: {
    SimpleGallery,
  },
  data() {
    return {
      images: [
        {
          largeURL:
            './img/ferial/1.jpg',
          thumbnailURL:
            './img/ferial/1m.jpg',
          width: 2048,
          height: 2048,
        },
        {
          largeURL:
            './img/ferial/2.jpg',
          thumbnailURL:
            './img/ferial/2m.jpg',
          width: 1063,
          height: 706,
        },
        {
          largeURL:
            './img/ferial/3.jpg',
          thumbnailURL:
            './img/ferial/3m.jpg',
          width: 1063,
          height: 706,
        },
        {
          largeURL:
            './img/ferial/4.jpg',
          thumbnailURL:
            './img/ferial/4m.jpg',
          width: 1063,
          height: 706,
        },
        {
          largeURL:
            './img/ferial/5.jpg',
          thumbnailURL:
            './img/ferial/5m.jpg',
          width: 1063,
          height: 706,
        },
        
        
      ],
    };
  },
};
</script>
<style>
#carteles-gallery img{
  transition: all ease .5s;
}
#carteles-gallery img:hover{
  transform: scale(1.1);
  transition: all ease .5s;
}
@media (width > 1200px) {
  #carteles-gallery {
    margin-inline: auto;
    display: grid;
    gap: 0;
    grid-template-columns: repeat(5, 200px);
    place-content: center;
	}
}
 @media (width < 1200px) {
  #carteles-gallery {
    display: grid;
    grid-template-columns: repeat(3, 200px);
    place-content: center;
	}
}
 @media (width < 768px) {
  #carteles-gallery {
    display: grid;
    grid-template-columns: repeat(2, 200px);
    place-content: center;
	}
}
 @media (width <= 360px) {
  #carteles-gallery {
    display: grid;
    grid-template-columns: repeat(2, 160px);
    place-content: center;
    img{
      width: 100%;
    }
	}
}
</style>