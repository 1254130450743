<template><div class="shape" v-html="shape"></div></template>
  
  <script>
  export default {
    name: 'ShapesShift',
    props: {
      type: {
        type: String,
        default: '1' 
      },
      color: {
        type: String,
        default: 'white' 
      }
    },
    data() {
      return {
        shape: '',
      };
    },
    mounted() {
      this.setShape();
    },
    methods: {
      setShape() {
        switch (this.type) {
          case '1':
            this.shape = `
                <svg data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 167 26">
                <path fill="${this.color}"" class="uuid-7a74c9bd-c859-4057-8cf9-53f028d0b475" d="M162,26c-9.95,0-15.12-5.27-19.27-9.5-3.7-3.77-6.38-6.5-12.13-6.5s-8.43,2.73-12.13,6.5c-4.15,4.23-9.32,9.5-19.27,9.5s-15.12-5.27-19.27-9.5c-3.7-3.77-6.38-6.5-12.13-6.5s-8.43,2.73-12.13,6.5c-4.15,4.23-9.32,9.5-19.27,9.5s-15.12-5.27-19.27-9.5c-3.7-3.77-6.38-6.5-12.13-6.5C2.24,10,0,7.76,0,5S2.24,0,5,0c9.95,0,15.12,5.27,19.27,9.5,3.7,3.77,6.38,6.5,12.13,6.5s8.43-2.73,12.13-6.5C52.68,5.27,57.85,0,67.8,0s15.12,5.27,19.27,9.5c3.7,3.77,6.38,6.5,12.13,6.5s8.43-2.73,12.13-6.5c4.15-4.23,9.32-9.5,19.27-9.5s15.12,5.27,19.27,9.5c3.7,3.77,6.38,6.5,12.13,6.5,2.76,0,5,2.24,5,5s-2.24,5-5,5Z"/>
                </svg>
                `;
            break;
          case '2':
            this.shape = `
            <svg id="uuid-e96a860f-0e32-47fc-ae6d-aebc512979b1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.11 34.11">
                <path fill="${this.color}" class="uuid-0d218bcf-91b4-4c2b-9452-d9deed110666" d="M17.06,0C7.64,0,0,7.64,0,17.06s7.64,17.06,17.06,17.06,17.06-7.64,17.06-17.06S26.48,0,17.06,0h0Z"/>
            </svg>
            `;
            break;
          case '3':
            this.shape = `
            <svg id="uuid-4e743e39-5749-490f-bf95-94e957265941" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.11 34.11">
               
                <path fill="${this.color}" class="uuid-6a436196-b0d5-4c27-871e-820ff600d80a" d="M17.06,10c3.89,0,7.06,3.17,7.06,7.06s-3.17,7.06-7.06,7.06-7.06-3.17-7.06-7.06,3.17-7.06,7.06-7.06M17.06,0C7.64,0,0,7.64,0,17.06s7.64,17.06,17.06,17.06,17.06-7.64,17.06-17.06S26.48,0,17.06,0h0Z"/>
                </svg>
            `;
            break;
          case '4':
            this.shape = `<svg id="uuid-09a1ee9f-1a50-46e2-8d3f-c66c5639429a" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.81 33.81">
  
  <path fill="${this.color}" class="uuid-f294ce39-1343-4fb3-bc43-85eaf0d9cf1f" d="M23.84,16.9l8.53-8.53c1.92-1.92,1.92-5.02,0-6.94-1.92-1.91-5.02-1.91-6.94,0l-8.53,8.53L8.37,1.44C6.46-.48,3.35-.48,1.44,1.44-.48,3.35-.48,6.46,1.44,8.37l8.53,8.53L1.44,25.44c-1.92,1.92-1.92,5.02,0,6.94.96.96,2.21,1.44,3.47,1.44s2.51-.48,3.47-1.44l8.53-8.53,8.53,8.53c.96.96,2.21,1.44,3.47,1.44s2.51-.48,3.47-1.44c1.92-1.92,1.92-5.02,0-6.94l-8.53-8.53Z"/>
</svg>`;
            break;
          case '5':
            this.shape = `<svg id="uuid-ce0955dc-f09d-4c98-8094-e67cfc7dcbba" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.92 33.92">
  
  <path fill="${this.color}" class="uuid-4387eb59-b4cf-462e-b2c0-9f8718fedc5c" d="M29.02,12.06h-7.15v-7.15c0-2.71-2.2-4.9-4.9-4.9s-4.9,2.2-4.9,4.9v7.15h-7.15c-2.71,0-4.9,2.2-4.9,4.9s2.2,4.9,4.9,4.9h7.15v7.15c0,2.71,2.2,4.9,4.9,4.9s4.9-2.2,4.9-4.9v-7.15h7.15c2.71,0,4.9-2.2,4.9-4.9s-2.2-4.9-4.9-4.9Z"/>
</svg>`;
            break;
          case '6':
            this.shape = `<svg id="uuid-5d0eed33-cdc9-40a1-a1af-2883bddc5ca6" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 34">
  
  <path fill="${this.color}" class="uuid-ac60d5b3-f26b-44dd-af6f-318b2f5675ef" d="M20,19.72l2.52,4.28h-5.03l2.52-4.28M20,0L0,34h40L20,0h0Z"/>
</svg>`;
            break;
          case '7':
            this.shape = `<svg id="uuid-c5c0550c-b418-438a-9264-045e2eb2e1d6" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 34">
  
  <path fill="${this.color}" class="uuid-08c43ce4-7682-470f-8bb3-fcf4d8749038" d="M20,34L0,0h40l-20,34Z"/>
</svg>`;
            break;
          case '8':
            this.shape = `
            <svg id="uuid-a9f36687-e735-4481-9bc5-71656ef34c5b" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
  
  <path fill="${this.color}" class="uuid-a373f6f4-ec88-4fc9-a6f8-dfdceee1bac1" d="M18,14.14l3.86,3.86-3.86,3.86-3.86-3.86,3.86-3.86M18,0L0,18l18,18,18-18L18,0h0Z"/>
</svg>`;
            break;
          case '9':
            this.shape = `
            <svg id="uuid-53ff3987-5da4-44be-b177-90fbb20927ba" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
  
  <rect fill="${this.color}" class="uuid-7a5355a2-b1b0-4d73-8cd2-7668f7b9df69" x="5.27" y="5.27" width="25.46" height="25.46" transform="translate(-7.46 18) rotate(-45)"/>
</svg>`;
            break;
          case '10':
            this.shape = `<svg id="uuid-8f33acfb-4512-486c-8d86-672232fa2fab" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.75 38.75">
  
  <polygon fill="${this.color}" class="uuid-71f951da-aa8e-42bb-94ac-d438413b90cc" points="19.87 0 24.77 10.23 35.81 7.68 30.86 17.88 39.75 24.92 28.69 27.41 28.72 38.75 19.87 31.66 11.03 38.75 11.06 27.41 0 24.92 8.88 17.88 3.94 7.68 14.98 10.23 19.87 0"/>
</svg>`;
            break;
          case '11':
            this.shape = `<svg id="uuid-28f1a272-babc-446a-abba-07ce1f41c313" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.77 40.77">
  
  <polygon fill="${this.color}" class="uuid-20447370-e1ae-4d86-9d88-f732fdf6d7c9" points="20.39 0 25.45 15.32 40.77 20.39 25.45 25.45 20.39 40.77 15.32 25.45 0 20.39 15.32 15.32 20.39 0"/>
</svg>`;
            break;
          case '12':
            this.shape = `<svg id="uuid-e2952fae-fbe8-4a7c-bd22-a81eaa170487" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.77 36.88">
  
  <polygon fill="${this.color}" class="uuid-edd06d75-4d2b-4fb4-bc25-225047e3bf8e" points="19.39 0 26.01 11.27 38.77 14.09 30.11 23.87 31.37 36.88 19.39 31.66 7.41 36.88 8.67 23.87 0 14.09 12.76 11.27 19.39 0"/>
</svg>`;
            break;
          default:
            this.shape = ['No se reconoce el tipo'];
        }
      }
    }
  };
  </script>
  
  <style scoped>
  /* Estilos específicos del componente */
  .shapes {
    display: flex;
  }
  
  .shape {
    margin: 0 10px;
    padding: 20px;
    /* El color de fondo se establece dinámicamente utilizando la propiedad color */
  }
  </style>
  