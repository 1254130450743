<template>
    <div :id="galleryID">
      <a
        v-for="(image, key) in imagesData"
        :key="key"
        :href="image.largeURL"
        :data-pswp-width="image.width"
        :data-pswp-height="image.height"
        target="_blank"
        rel="noreferrer"
      >
        <img :src="image.thumbnailURL" alt="" />
      </a>
    </div>
  </template>
  
  <script>
  import PhotoSwipeLightbox from 'photoswipe/lightbox';
  import 'photoswipe/style.css';
  
  export default {
    name: 'SimpleGallery',
    props: {
      galleryID: String,
      images: Array,
    },
    
    setup(props) {
      return {
        imagesData: props.images,
      };
    },
    mounted() {
        console.log('mounted')
      if (!this.lightbox) {
        console.log('lb')
        this.lightbox = new PhotoSwipeLightbox({
          gallery: '#' + this.$props.galleryID,
          children: 'a',
          pswpModule: () => import('photoswipe'),
        });
        this.lightbox.init();
      }
    },
    unmounted() {
      if (this.lightbox) {
        this.lightbox.destroy();
        this.lightbox = null;
      }
    },
    methods: {},
  };
  </script>
  