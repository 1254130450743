<template><div>
    <div class="container">
      <h1>Evento {{ $route.params.id }}</h1>
    </div>
</div></template>
  
  <script>
  export default {
    name: 'EventoView',
    data() {
      return {
        
      };
    }
  };
  </script>
  
  <style scoped>
  </style>
  