<template>
  <customNav></customNav>
  <ul class="redes">
    <li>
      <a target="_blank" href="https://es-la.facebook.com/ferianacionaldesanmarcosoficial/"
        ><svg
          width="9"
          height="20"
          viewBox="0 0 9 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.29749 20V10.6154H0V7.23652H2.29749V4.3505C2.29749 2.08264 3.65463 0 6.78176 0C8.04789 0 8.98413 0.1311 8.98413 0.1311L8.91036 3.28642C8.91036 3.28642 7.95554 3.27638 6.9136 3.27638C5.78591 3.27638 5.60524 3.83768 5.60524 4.7693V7.23652H9L8.85229 10.6154H5.60524V20H2.29749Z"
          />
        </svg>
      </a>
    </li>
    <li>
      <a href="https://twitter.com/FNSM_Oficial" target="_blank"
        ><svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.2825 18.7499C13.83 18.7499 17.9587 12.4961 17.9587 7.08239C17.9587 6.90739 17.9588 6.72989 17.9513 6.55489C18.7551 5.973 19.4488 5.25258 20 4.42739C19.2491 4.75845 18.4535 4.97665 17.6388 5.07489C18.4969 4.56194 19.1397 3.75485 19.4475 2.80364C18.6412 3.28129 17.7588 3.61661 16.8387 3.79489C16.2202 3.13617 15.4018 2.69979 14.5101 2.55334C13.6185 2.40689 12.7034 2.55854 11.9067 2.98481C11.1099 3.41108 10.476 4.08818 10.1031 4.91122C9.73012 5.73425 9.63897 6.65731 9.84375 7.53739C8.21218 7.45558 6.61602 7.03172 5.1588 6.29332C3.70159 5.55491 2.41586 4.51844 1.385 3.25114C0.861675 4.15499 0.701967 5.22414 0.938308 6.24147C1.17465 7.2588 1.78932 8.14805 2.6575 8.72864C2.00692 8.70654 1.37065 8.53184 0.8 8.21864V8.27489C0.801122 9.22174 1.12913 10.1392 1.72859 10.8721C2.32805 11.605 3.16218 12.1085 4.09 12.2974C3.73783 12.3944 3.37404 12.4428 3.00875 12.4411C2.75123 12.4419 2.49423 12.4181 2.24125 12.3699C2.50348 13.185 3.01409 13.8976 3.70156 14.408C4.38903 14.9184 5.21892 15.201 6.075 15.2161C4.62069 16.3584 2.82425 16.9779 0.975 16.9749C0.649151 16.9763 0.323529 16.9575 0 16.9186C1.87689 18.1152 4.05662 18.7506 6.2825 18.7499V18.7499Z"
          />
        </svg>
      </a>
    </li>
    <li>
      <a href="https://www.instagram.com/fnsm_oficial/" target="_blank"
        ><svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.0022 15.1277C7.16453 15.1277 4.87563 12.8383 4.87563 10C4.87563 7.16166 7.16453 4.87225 10.0022 4.87225C12.8399 4.87225 15.1288 7.16166 15.1288 10C15.1288 12.8383 12.8399 15.1277 10.0022 15.1277ZM10.0022 6.66629C8.16843 6.66629 6.66927 8.16133 6.66927 10C6.66927 11.8387 8.16397 13.3337 10.0022 13.3337C11.8405 13.3337 13.3352 11.8387 13.3352 10C13.3352 8.16133 11.836 6.66629 10.0022 6.66629V6.66629ZM16.5343 15.3375C16.5343 14.6725 15.9989 14.1415 15.3385 14.1415C14.6737 14.1415 14.1428 14.677 14.1428 15.3375C14.1428 15.998 14.6782 16.5335 15.3385 16.5335C15.9989 16.5335 16.5343 15.998 16.5343 15.3375ZM19.9297 14.1236C19.8539 15.7258 19.488 17.1449 18.3146 18.3142C17.1456 19.4834 15.7267 19.8494 14.1249 19.9297C12.4741 20.0234 7.52593 20.0234 5.87507 19.9297C4.27775 19.8538 2.8589 19.4879 1.68544 18.3186C0.511991 17.1494 0.150586 15.7302 0.0702733 14.1281C-0.0234244 12.4768 -0.0234244 7.52761 0.0702733 5.87638C0.146124 4.27424 0.511991 2.85507 1.68544 1.68582C2.8589 0.516568 4.27329 0.150619 5.87507 0.0702892C7.52593 -0.0234294 12.4741 -0.0234294 14.1249 0.0702892C15.7267 0.146157 17.1456 0.512105 18.3146 1.68582C19.4835 2.85507 19.8494 4.27424 19.9297 5.87638C20.0234 7.52761 20.0234 12.4724 19.9297 14.1236V14.1236ZM17.797 4.10465C17.449 3.22995 16.7752 2.55606 15.8963 2.2035C14.58 1.68136 11.4568 1.80185 10.0022 1.80185C8.54769 1.80185 5.41997 1.68582 4.1082 2.2035C3.23369 2.5516 2.55996 3.22548 2.20747 4.10465C1.68544 5.42118 1.80591 8.54513 1.80591 10C1.80591 11.4549 1.68991 14.5833 2.20747 15.8953C2.55549 16.7701 3.22922 17.4439 4.1082 17.7965C5.42443 18.3186 8.54769 18.1981 10.0022 18.1981C11.4568 18.1981 14.5845 18.3142 15.8963 17.7965C16.7708 17.4484 17.4445 16.7745 17.797 15.8953C18.319 14.5788 18.1985 11.4549 18.1985 10C18.1985 8.54513 18.319 5.41671 17.797 4.10465Z"
          />
        </svg>
      </a>
    </li>
    <li>
      <a href="https://www.tiktok.com/@fnsm_oficial" target="_blank"
        ><svg
          width="14"
          height="16"
          viewBox="0 0 14 16"
      
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 0H9.98C10.124 0.715 10.52 1.617 11.215 2.512C11.895 3.389 12.797 4 14 4V6C12.247 6 10.93 5.186 10 4.171V11C10 11.9889 9.70676 12.9556 9.15735 13.7779C8.60794 14.6001 7.82705 15.241 6.91342 15.6194C5.99979 15.9978 4.99446 16.0969 4.02455 15.9039C3.05465 15.711 2.16373 15.2348 1.46447 14.5355C0.765206 13.8363 0.289002 12.9454 0.0960759 11.9755C-0.0968503 11.0055 0.00216642 10.0002 0.380605 9.08658C0.759043 8.17295 1.39991 7.39206 2.22215 6.84265C3.0444 6.29324 4.0111 6 5 6V8C4.40666 8 3.82664 8.17595 3.33329 8.50559C2.83994 8.83524 2.45543 9.30377 2.22836 9.85195C2.0013 10.4001 1.94189 11.0033 2.05765 11.5853C2.1734 12.1672 2.45912 12.7018 2.87868 13.1213C3.29824 13.5409 3.83279 13.8266 4.41473 13.9424C4.99667 14.0581 5.59987 13.9987 6.14805 13.7716C6.69623 13.5446 7.16477 13.1601 7.49441 12.6667C7.82406 12.1734 8 11.5933 8 11V0Z"
     
          />
        </svg>
      </a>
    </li>
    <li>
      <a href="https://www.youtube.com/@PFNSM" target="_blank"
        ><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path d="M12.244 4c.534.003 1.87.016 3.29.073l.504.022c1.429.067 2.857.183 3.566.38c.945.266 1.687 1.04 1.938 2.022c.4 1.56.45 4.602.456 5.339l.001.152v.174c-.007.737-.057 3.78-.457 5.339c-.254.985-.997 1.76-1.938 2.022c-.709.197-2.137.313-3.566.38l-.504.023c-1.42.056-2.756.07-3.29.072l-.235.001h-.255c-1.13-.007-5.856-.058-7.36-.476c-.944-.266-1.687-1.04-1.938-2.022c-.4-1.56-.45-4.602-.456-5.339v-.326c.006-.737.056-3.78.456-5.339c.254-.985.997-1.76 1.939-2.021c1.503-.419 6.23-.47 7.36-.476zM9.999 8.5v7l6-3.5z"/></svg>
      </a>
    </li>
  </ul>
  <router-view />
</template>
<script>
import customNav from "@/components/navbar.vue";
export default {
  components: {
    customNav
  },
  setup() {}
};
</script>
<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

</style>
