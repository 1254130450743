<template>
  <SimpleGallery galleryID="convocatorias-gallery" :images="images" />
</template>

<script>
import SimpleGallery from './PhotoSwipe.vue';

export default {
  name: 'App',
  components: {
    SimpleGallery,
  },
  data() {
    return {
      images: [
        {
          largeURL:
            './img/convocatorias/carrera_meseros.jpg',
          thumbnailURL:
            './img/convocatorias/carrera_meserosm.jpg',
          width: 1642,
          height: 2551,
        },
        {
          largeURL:
            './img/convocatorias/cuenta-tu-feria.jpg',
          thumbnailURL:
            './img/convocatorias/cuenta-tu-feriam.jpg',
            width: 1642,
          height: 2551,
        },
        {
          largeURL:
            './img/convocatorias/pinta-tu-feria.jpg',
          thumbnailURL:
            './img/convocatorias/pinta-tu-feriam.jpg',
            width: 1642,
          height: 2551,
        },
        
        
      ],
    };
  },
};
</script>
<style>
@media (width > 1200px) {
  #convocatorias-gallery img{
  transition: all ease .5s;
}
#convocatorias-gallery img:hover{
  transform: scale(1.1);
  transition: all ease .5s;
}
  #convocatorias-gallery {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, 300px);
    place-content: center;
}
}
 @media (width < 1200px) {
  #convocatorias-gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-content: center;
}
}
 @media (width < 768px) {
  #convocatorias-gallery {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    place-content: center;
}
}
 @media (width < 992px) {
  #convocatorias-gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
}
}
 
</style>