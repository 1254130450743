<!-- MiComponente.vue -->
<template>
    <div>
      
      <div>
        
        <div v-if="ubicacion == 'perimetro'">
            <h3 class="text-white mb-4">Mapa del Perimetro de la Feria</h3>
            <div id="mapa-perimetro" class="mapa"></div>
        </div>
        <div v-if="ubicacion == 'isla'">
            <h3 class="text-white mb-4">Mapa de la Isla San Marcos</h3>
            <div id="mapa-isla" class="mapa"></div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'MapasFnsm',
    props: {
      ubicacion: String 
    }
  };
  </script>
  
  <style scoped>
  a{
    display: none!important;
  }
  .mapa{
    border-radius: 10px;
    overflow: hidden;
  }
  </style>
  