<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-acrylic sticky-top">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <div id="fnsmLogo"></div>
      </a>

      <div>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
  
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link" aria-current="page" href="#eventos">Eventos</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" aria-current="page" href="#mapas">Mapas</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#programa">Programa Cultural</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#ferial">Ferial</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#convocatorias">Convocatorias</a>
            </li>
           
            <li class="nav-item">
              <a class="nav-link" href="./docs/Queplan_Feria_San_Marcos_2024.pdf" download>¿Qué Plan?</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://www.vivaaguascalientes.com/" target="_blank">
                <img src="@/assets/img/2024/logo_viva_ags_nav.svg" class="vivaags_nav">
              </a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" href="#">Mapa</a>
            </li>
             -->
          </ul>
        </div>
      </div>

    </div>
  </nav>
</template>


<script>
export default {
  name: "navBar",
  setup() {}
};
</script>
