<template>
  <div>
    <header id="programa-v">
      <div class="container dp__relative">

        <div class="searchWrapper">
          <div class="searchFilters">
            <div class="fechaContainer w-100">
              <p class="nombre">Fecha</p>

              <Datepicker
                class="w-100"
                v-model="date"
                locale="es"
                :format="format"
                fixedStart
                autoApply
                
                @cleared="alertFn"
              ></Datepicker>
            </div>
            <div class="d-none">
              <p class="nombre">Lugar</p>
              <v-select
                :options="llugares"
                v-model="formData.lugar"
                placeholder="Todos"
              ></v-select>
            </div>
            <div class="d-none">
              <p class="nombre">Tipo</p>
              <v-select
                :options="cat"
                label="descripcion"
                v-model="formData.categoria"
                placeholder="Todos"
                clearable
              >
                <template #option="{ descripcion }">
                  {{ descripcion }}
                </template>
                <template #selected-option="{ descripcion }">
                  <div style="display: flex; align-items: baseline">
                    {{ descripcion }}
                  </div>
                </template>
              </v-select>
            </div>
            <button class="btn btn-warning btn-filter" @click="searchEvent">Filtrar</button>
          </div>
          
        </div>

        <!-- <img id="i-leftbottom" src="@/assets/img/mosaicomini.svg" alt="" /> -->
      </div>
    </header>
    <div class="e container mt-5 mb-5">
      <div class="row">
        <div
          class="col-lg-3 gy-3"
          v-for="(item, index) in lprincipal"
          :key="index"
        >
          <div :id="'event-'+item.id_evento" data-toggle="modal" :data-target="'#modal-'+item.id_evento" @click="openModal(item.id_evento)">
          <!-- <a
            target="_blank"
            class="eCard_link"
            :href="
              'https://eservicios2.aguascalientes.gob.mx/eventosPortal/evento/' +
              item.id_evento +
              '/' +
              item.categoria
            "
          > -->
            <div class="eCard">
              <div
                class="cartaevento"
                :style="{ backgroundImage: `url(${item.imagen + '/300'})` }"
              >
                <!-- <img v-bind:src="item.imagen" alt="" /> -->
                <div v-show="item.lhorario.length >= 2">
                  <p class="fechaFinal">
                    <span class="add">al</span>
                    <span class="dia">
                      {{
                        moment(
                          item.lhorario[item.lhorario.length - 1].horario
                        ).format("D")
                      }}
                    </span>

                    <span class="mes">
                      {{
                        moment(
                          item.lhorario[item.lhorario.length - 1].horario
                        ).format("MMMM")
                      }}
                    </span>
                  </p>
                </div>
                <p class="fecha">
                  <span class="dia">
                    {{ moment(item.lhorario[0].horario).format("D") }} <br
                  /></span>
                  <span class="mes">
                    {{ moment(item.lhorario[0].horario).format("MMMM") }}
                  </span>
                </p>
              </div>
              <div class="datos">
                <p class="nombre">{{ item.nombre }}</p>
                <p class="lugar">{{ item.subzona }}</p>
                <div class="cat">
                  {{ moment(item.lhorario[0].horario).format("HH") }} Hrs |
                  {{ item.categoria }}
                </div>
                
                
              </div>
            </div> <!-- /eCard -->
          </div><!-- /eCard_link -->
          
        </div><!-- /loop cols -->
      </div><!-- /row cols -->

      <div v-for="(modal, index) in lprincipal" :key="index" class="modal fade" :id="'modal-'+modal.id_evento" tabindex="-1" role="dialog" :aria-labelledby="'modal-'+modal.id_evento+'Label'" aria-hidden="true">

        <div class="modal-dialog" role="document">
          <div class="modal-content">
            
            <div class="modal-content-wrapp">
              
              <div class="modal-header">
                
                
                <div class="close" @click="closeModal()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512"><path  d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208s208-93.31 208-208S370.69 48 256 48m75.31 260.69a16 16 0 1 1-22.62 22.62L256 278.63l-52.69 52.68a16 16 0 0 1-22.62-22.62L233.37 256l-52.68-52.69a16 16 0 0 1 22.62-22.62L256 233.37l52.69-52.68a16 16 0 0 1 22.62 22.62L278.63 256Z"/></svg>
                </div>

                <div class="back" @click="closeModal()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256"><path d="M228 128a12 12 0 0 1-12 12H69l51.52 51.51a12 12 0 0 1-17 17l-72-72a12 12 0 0 1 0-17l72-72a12 12 0 0 1 17 17L69 116h147a12 12 0 0 1 12 12"/></svg>
                  <span>Volver</span>
                </div>
                <h3 class="modal-title text-left">{{ modal.nombre.toUpperCase() }}</h3>
                <p class="text-left text-capitalize" v-if="modal.gratis">Evento Gratuito</p>
                <p class="text-left text-capitalize" v-else>{{ modal.costo.toLowerCase()}}</p>
              </div>
              
              <div class="modal-body">
                <div class="row text-left text-capitalize">
                  <div class="about col-md-6">
                    <h6 class="title"> 
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16"><path fill="currentColor" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412l-1 4.705c-.07.34.029.533.304.533c.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598c-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081l.082-.381l2.29-.287zM8 5.5a1 1 0 1 1 0-2a1 1 0 0 1 0 2"/></svg>
                      <span>Acerca del Evento</span>
                    </h6>
                    <p>{{ modal.descripcion.toLowerCase() }}</p>

                    <p><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 36 36"><path fill="currentColor" d="M32.25 6h-4v3a2.2 2.2 0 1 1-4.4 0V6H12.2v3a2.2 2.2 0 0 1-4.4 0V6h-4A1.78 1.78 0 0 0 2 7.81v22.38A1.78 1.78 0 0 0 3.75 32h28.5A1.78 1.78 0 0 0 34 30.19V7.81A1.78 1.78 0 0 0 32.25 6M10 26H8v-2h2Zm0-5H8v-2h2Zm0-5H8v-2h2Zm6 10h-2v-2h2Zm0-5h-2v-2h2Zm0-5h-2v-2h2Zm6 10h-2v-2h2Zm0-5h-2v-2h2Zm0-5h-2v-2h2Zm6 10h-2v-2h2Zm0-5h-2v-2h2Zm0-5h-2v-2h2Z" class="clr-i-solid clr-i-solid-path-1"/><path fill="currentColor" d="M10 10a1 1 0 0 0 1-1V3a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1" class="clr-i-solid clr-i-solid-path-2"/><path fill="currentColor" d="M26 10a1 1 0 0 0 1-1V3a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1" class="clr-i-solid clr-i-solid-path-3"/><path fill="none" d="M0 0h36v36H0z"/></svg> Fechas y Horarios:</p>
                    
                    <div class="horario-wrapper">
                      <div v-for="(horario, index) in modal.lhorario" :key="index" class="horario-item">
                      {{ moment(horario.horario).format("DD/MM/YYYY HH:mm A") }}
                    </div>
                    </div>

                  </div>

                  <div class="location col-md-6">
                    <h6 class="title"> 
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 1200 1200"><path fill="currentColor" d="M600 0C350.178 0 147.656 202.521 147.656 452.344c0 83.547 16.353 169.837 63.281 232.031L600 1200l389.062-515.625c42.625-56.49 63.281-156.356 63.281-232.031C1052.344 202.521 849.822 0 600 0m0 261.987c105.116 0 190.356 85.241 190.356 190.356C790.356 557.46 705.116 642.7 600 642.7s-190.356-85.24-190.356-190.356S494.884 261.987 600 261.987"/></svg>
                      <span>Ubicación</span>
                    </h6>
                    <p>{{ modal.zona.toLowerCase() }} / {{ modal.subzona.toLowerCase() }}</p>
                    
                    <a :href="'https://www.google.com.mx/maps/@'+modal.ltsubzona+','+modal.lnsubzona+',20z?entry=ttu'" class="btn btn-secondary" target="_blank">
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M19.527 4.799c1.212 2.608.937 5.678-.405 8.173c-1.101 2.047-2.744 3.74-4.098 5.614c-.619.858-1.244 1.75-1.669 2.727c-.141.325-.263.658-.383.992c-.121.333-.224.673-.34 1.008c-.109.314-.236.684-.627.687h-.007c-.466-.001-.579-.53-.695-.887c-.284-.874-.581-1.713-1.019-2.525c-.51-.944-1.145-1.817-1.79-2.671zM8.545 7.705l-3.959 4.707c.724 1.54 1.821 2.863 2.871 4.18q.371.465.737.936l4.984-5.925l-.029.01c-1.741.601-3.691-.291-4.392-1.987a3.4 3.4 0 0 1-.209-.716c-.063-.437-.077-.761-.004-1.198zM5.492 3.149l-.003.004c-1.947 2.466-2.281 5.88-1.117 8.77l4.785-5.689l-.058-.05zM14.661.436l-3.838 4.563l.027-.01c1.6-.551 3.403.15 4.22 1.626c.176.319.323.683.377 1.045c.068.446.085.773.012 1.22l-.003.016l3.836-4.561A8.38 8.38 0 0 0 14.67.439zM9.466 5.868L14.162.285l-.047-.012A8.3 8.3 0 0 0 11.986 0a8.44 8.44 0 0 0-6.169 2.766l-.016.018z"/></svg>
                      Ver en Google maps</a>

                    

                  </div>
                </div>

                <!-- <pre><code>{{ modal }}</code></pre> -->

              </div>

            </div>

            <picture class="modal-picture">
              <img :src="modal.imagen.replace('http','https') + '/400'">
              <div class="fecha-modal text-uppercase">

                  <div class="start-event">
                    <span class="dia-modal">
                    {{ moment(modal.lhorario[0].horario).format("D") }}  </span>
                    <span class="mes-modal"> {{ moment(modal.lhorario[0].horario).format("MMMM") }}</span>
                  </div>

                  <div v-if="modal.lhorario.length >= 2" class="last-event">
                    
                      <span class="add"> Al </span>
                      <span class="dia-modal">
                        {{
                          moment(
                            modal.lhorario[modal.lhorario.length - 1].horario
                          ).format("D")
                        }}
                      </span>

                      <span class="mes-modal">
                        {{
                          moment(
                            modal.lhorario[modal.lhorario.length - 1].horario
                          ).format("MMMM")
                        }}
                      </span>
                    
                  </div>

                  </div>
            </picture>
            
          </div>
        </div>  

      </div><!--/Modal-->

      <div class="row" v-if="lprincipal == ''">
        
        <div class="col">
          <!-- <img src="@/assets/img/bannersinEventos.png" alt="" class="img-fluid mb-5"/> -->
          <h1 class="title text-white text-center">NO HAY EVENTOS</h1>
        </div>
      </div>
    </div>




    
  </div>
</template>
<script>
import { ref } from "vue";
import { onMounted } from "vue";
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import moment from "moment";
import "moment/locale/es";

import axios from "axios";

export default {
  name: "programaEventos",
  components: {

    Datepicker,
    vSelect
  },
  data() {
    return {
      ActiveModal: null,
      cat: [
        { id: 11, descripcion: "Actividades Enoturísticas" },
        { id: 26, descripcion: "Actividades Múltiples" },
        { id: 12, descripcion: "Animación" },
        { id: 1, descripcion: "Artísticos" },
        { id: 24, descripcion: "Capacitación" },
        { id: 27, descripcion: "Comercio" },
        { id: 25, descripcion: "Convocatorias" },
        { id: 3, descripcion: "Cultura Regional" },
        { id: 15, descripcion: "Danza" },
        { id: 8, descripcion: "Danza Folclórica" },
        {
          id: 4,
          descripcion: "Deporte"
        },
        { id: 20, descripcion: "Divulgación Científica" },
        { id: 22, descripcion: "Entretenimiento" },
        { id: 13, descripcion: "Espectáculo Multidisciplinario" },
        { id: 18, descripcion: "Espectáculos" },
        { id: 23, descripcion: "Exhibiciones" },
        { id: 2, descripcion: "Ferias, Festivales y Tradiciones" },
        { id: 10, descripcion: "Gastronomía" },
        { id: 5, descripcion: "Infantil" },
        { id: 7, descripcion: "Museos" },
        { id: 14, descripcion: "Música" },
        { id: 9, descripcion: "Navidad" },
        {
          id: 21,
          descripcion: "Observación Astronómica"
        },
        { id: 17, descripcion: "Taurinos" },
        { id: 6, descripcion: "Teatro" },
        { id: 16, descripcion: "Tradicionales" }
      ]
    };
  },
  methods: {
      openModal(eventId) {
        const modal = document.getElementById('modal-'+eventId);
        modal.classList.add('show', 'd-block');
        this.ActiveModal = eventId;
        console.log('Modal Activa: ' + this.ActiveModal )

        let html = document.documentElement;
        console.log(html)
        html.classList.add('lock');
        
      },
      closeModal() {
        const modal = document.getElementById('modal-'+this.ActiveModal);
        modal.classList.remove('show', 'd-block');
        let html = document.documentElement;
        html.classList.remove('lock');

      }
    },
  setup() {
    let formData = ref({});
    let lprincipal = ref(null);
    let llugares = ref([
      "Todos",
      "ARENA SAN MARCOS",
      "CALLE VENUSTIANO CARRANZA",
      "CASA DE LA CULTURA VÍCTOR SANDOVAL",
      "CASA JESÚS TERÁN",
      "CECADI",
      "CENTRO DE ARTES VISUALES",
      "COMPLEJO DE LA ISLA SAN MARCOS",
      "CENTRO DE INVESTIGACIÓN Y ESTUDIOS LITERARIOS DE AGUASCALIENTES (CIELA)",
      "CLUB CAMPESTRE",
      "CLUB DEPORTIVO PULGAS PANDAS",
      "COMPLEJO CHARRO",
      "EXPLANADA AGROALIMENTARIA",
      "EXPO GANADERA",
      "FORO DEL LAGO",
      "GALERÍA EX ESCUELA DE CRISTO",
      "GALERÍA DE ARTE EL OBRAJE",
      "GIMNASIO OLÍMPICO",
      "ISLA SAN MARCOS",
      "JARDIN DE SAN MARCOS",
      "MUSEO AGUASCALIENTES",
      "MUSEO ESPACIO",
      "MUSEO DE ARTE CONTEMPORÁNEO NÚMERO 8",
      "MUSEO JOSÉ GUADALUPE POSADA",
      "MUSEO DE LA INSURGENCIA",
      "MUNICIPIO DE TEPEZAlÁ",
      "PATIO JESUS F. CONTRERAS",
      "PALACIO DE GOBIERNO",
      "PALENQUE DE LA FERIA",
      "PATIO DE LAS JACARANDAS",
      "PLAZA DE TOROS MONUMENTAL",
      "PLAZA PATRIA",
      "PRESA PRESIDENTE CALLES",
      "RANCHO PEÑA AZUL",
      "SALÓN DE LA TERCERA EDAD",
      "TEATRO AGUASCALIENTES",
      "TEATRO MORELOS",
      "TEATRO ANTONIO LEAL Y ROMERO",
      "UNIVERSIDAD DE LAS ARTES",
      "VELARIA FORO",
      "VELÓDROMO JUAN ALFREDO MORALES SHAADI"
    ]);
    let lcat = "";

    onMounted(() => {
      // date.value = new Date(2024, (4 - 1), 13);
      searchEvent();
    });

    
    // const date = ref(new Date());

    

      let date;

      function regresarFecha(){
        if (new Date() < new Date(2024,3,12)){
          return ref(new Date(2024, 3, 12))
        }else {
          return ref(new Date())
        }
      }

      

      date = regresarFecha();

    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      formData.value.fecha = `${day}/${month}/${year}`;
      // formData.test = self.date;
      console.log('FD '+formData.value.fecha);

      return formData.value.fecha;
    };



    

    const searchEvent = async () => {
      console.log("----Fecha----");
      console.log(formData.value.fecha);
      console.log("----Lugar----");
      console.log(formData.value.lugar);
      console.log("----Categoria----");
      console.log(formData.value.categoria);

      formData.value.dep = "PFNSM";
      const params = JSON.parse(JSON.stringify(formData.value));

      if (params.lugar !== undefined) {
        params.lugar = params.lugar !== "Todos" ? params.lugar : "";
      }

      params.lugar =
        params.lugar != undefined && params.lugar != null ? params.lugar : "";
      params.categoria =
        params.categoria != undefined && params.categoria != null
          ? params.categoria.id
          : "";
      try {
        var catalogos = {
          method: "post",
          url: "https://eservicios2.aguascalientes.gob.mx/geaws/api/gea/eve/ws/ct",
          headers: {}
        };

        await axios(catalogos)
          .then(function (rsct) {
            //lcat.value = JSON.stringify(rsct.data);

            console.log("************** rsct ***********************");
            console.log(rsct);
          })
          .catch(function (erct) {
            console.log(erct);
          });
      } catch (erctt) {
        console.log(erctt);
      }

      try {
        var config = {
          method: "post",
          url: "https://eservicios2.aguascalientes.gob.mx/geaws/api/gea/eve/ws/aut",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },

          data: Object.keys(params)
            .map((key) => `${key}=${decodeURIComponent(params[key])}`)
            .join("&")
        };

        await axios(config)
          .then(function (response) {
            lprincipal.value = response.data;
            // Los eventos se ordenan por fecha ascendente
            lprincipal.value = lprincipal.value.sort((a, b) => {
              if (new Date(a.lhorario[0].horario) > new Date(b.lhorario[0].horario)) {
                  return 1;
              } else if (new Date(a.lhorario[0].horario) < new Date(b.lhorario[0].horario)) {
                  return -1;
              } else {
                return 0;
              }
            });

            
            console.log("********Lprincipal*********");
            console.log(lprincipal.value);
            console.log("----Params----");
            console.log(params);
            console.log("----Config----");
            console.log(config);
          })
          .catch(function (error) {
            console.log(error);
            console.log(config);
          });
      } catch (error) {
        console.log(error);
      }
    };

    const alertFn = () => {
      console.log("----Value cleared----");
      formData.value.fecha = "";
    };

    return {
      alertFn,
      date,
      format,
      searchEvent,
      formData,
      lprincipal,
      llugares,
      lcat
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("updated", val);
      }
    }
  },
  created() {
    let self = this;
    self.moment = moment;
  }
};
</script>
<style>
.btn-filter{
  max-height: 40px;
}
.searchFilters {
    align-items: flex-end;
}
.dp__input {
    
    color: white;
    border: 1px solid #dec04c !important;
}
</style>