<template>

  <main class="main">

    <section class="hero intro text-center">
      <div class="container">
        <h3 class="title text-center text-white text-uppercase bg-dark official-portal">Portal Oficial</h3>
        <img class="form form-sphere"  src="@/assets/img/2024/form_sphere.webp" alt="">
        <img class="form form-cube" src="@/assets/img/2024/form_cube.webp" alt="">
        <div id="fnsmIntro" class="fnsm-intro"></div>
      </div>
      
    </section>
    <section class="section banner-app mb-5 pb-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 text-white app-titles">
            <h1 class="display-title">Descarga <br/>la App Oficial</h1>
            <p class="mt-3">de la <b>Feria Nacional de San Marcos 2024</b>.</p>
            <p>Encuéntrala como FNSM Oficial. Ahí podrás consultar todos los eventos culturales de tu interés, conciertos, fechas, horarios, ubicación, puntos de Internet gratuito y más.</p>
            <div class="row store-links">
              <div class="col">
                <a href="https://apps.apple.com/mx/app/fnsm-oficial/id1092917002" target="_blank"><img src="@/assets/img/2024/appstore.svg" alt="Applicación Oficial FNSM Appstore" class="w-100"></a>
              </div>
              <div class="col">
                <a href="https://play.google.com/store/apps/details?id=mx.gob.aguascalientes.fnsm&hl=es_MX&gl=US&pli=1" target="_blank"><img src="@/assets/img/2024/playstore.svg" alt="Applicación Oficial FNSM Playstore" class="w-100"></a>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <img src="@/assets/img/2024/banner_app.png" alt="Applicación Oficial FNSM" class="w-100">
            
          </div>
        </div>
      </div>
    </section>
    <section class="poster d-none">
      <div class="container">
        <img src="@/assets/img/2024/form_music.webp" class="element form-micro mouse_move" value="5">
        <img src="@/assets/img/2024/cartel_m.webp" alt="" class="poster-img mobile" id="posterImg">
        <img src="@/assets/img/2024/cartel_xl.webp" alt="" class="poster-img desktop" id="posterImg">
        <img src="@/assets/img/2024/form_music.webp" class="element form-music mouse_move" value="-5">
      </div>

    </section>

    

    <section id="eventos" class="section program">
      <div class="container text-white text-center">
        <!-- <img src="@/assets/img/2024/programa.svg" class="centered-inline title-program"> -->
        
        <div class="title-shapes mb-5">
          <Shapes type="1" color="#DB5A83"></Shapes>
          <Shapes type="2" color="#08cbbc"></Shapes>
          <Shapes type="3" color="#FFBC45"></Shapes>
          <h1 class="text-white text-center text-uppercase">Eventos FNSM 2024</h1>
          <Shapes type="3" color="#FFBC45"></Shapes>  
          <Shapes type="2" color="#08cbbc"></Shapes>  
          <Shapes type="1" color="#DB5A83"></Shapes>  
        </div>

        <Programa></Programa>
        <a href="./docs/programa_feria_nacional_san_marcos_2024.pdf" type="button" class="btn btn-secondary btn-lg text-white" download>
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="white" d="M18.948 11.112C18.511 7.67 15.563 5 12.004 5c-2.756 0-5.15 1.611-6.243 4.15c-2.148.642-3.757 2.67-3.757 4.85c0 2.757 2.243 5 5 5h1v-2h-1c-1.654 0-3-1.346-3-3c0-1.404 1.199-2.757 2.673-3.016l.581-.102l.192-.558C8.153 8.273 9.898 7 12.004 7c2.757 0 5 2.243 5 5v1h1c1.103 0 2 .897 2 2s-.897 2-2 2h-2v2h2c2.206 0 4-1.794 4-4a4.008 4.008 0 0 0-3.056-3.888"/><path fill="white" d="M13.004 14v-4h-2v4h-3l4 5l4-5z"/></svg> Programa General</a>
        <!-- <Eventos></Eventos> -->

      </div>
    </section>

    <section class="section" id="mapas">
      <div class="container-fluid">
      
        <div class="title-shapes mb-5">
          <Shapes type="6" color="#c85885"></Shapes>
          <Shapes type="5" color="#08cbbc"></Shapes>
          <Shapes type="4" color="#FFBC45"></Shapes>
          <h1 class="text-white text-center text-uppercase">Mapas</h1>
          <Shapes type="4" color="#FFBC45"></Shapes>  
          <Shapes type="5" color="#08cbbc"></Shapes>  
          <Shapes type="6" color="#c85885"></Shapes>  
        </div>

        <div class="row">
          <div class="col-12 col-md-6 mapa-perimetro"><Mapas ubicacion="perimetro"></Mapas></div>
          <div class="col-12 col-md-6 mapa-isla"><Mapas ubicacion="isla"></Mapas></div>
        </div>
      </div>
    </section>

    <section class="section bg-hippie-blue" id="programa">
      <div class="container text-white text-center">
        <!-- <img src="@/assets/img/2024/cartelera.svg" class="mb-5 title-posters"> -->
        
        <div class="title-shapes mb-5">
          <Shapes type="7" color="#08cbbc"></Shapes>
          <Shapes type="8" color="#FFBC45"></Shapes>
          <Shapes type="9" color="#B59BB7"></Shapes>
          <h1 class="text-white text-center text-uppercase">Programa Cultural</h1>
          <Shapes type="9" color="#B59BB7"></Shapes>  
          <Shapes type="8" color="#FFBC45"></Shapes>  
          <Shapes type="7" color="#08cbbc"></Shapes>  
        </div>

        <p>Estas son las actividades culturales que con el Instituto Cultural de Aguascalientes tendremos  durante la Feria Nacional de San Marcos 2024, con grandes exponentes que podrás disfrutar del 13 de abril al 05 de mayo en todos los foros que se ofrecen para la familia. </p>
        <p><b>¡No te lo puedes perder! 👇🏻🤩</b></p>
        <Carteles></Carteles>
      </div>
    </section>

    <section class="section bg-warning" id="ferial">
      <div class="container text-white text-center">
        <!-- <img src="@/assets/img/2024/cartelera.svg" class="mb-5 title-posters"> -->
        <div class="title-shapes mb-5">
          <Shapes type="12" color="var(--bs-primary)"></Shapes>
          <Shapes type="10" color="var(--bs-hippie-blue)"></Shapes>
          <Shapes type="11" color="var(--bs-info)"></Shapes>
          <h1 class="text-white text-center text-uppercase">Ferial FNSM 2024</h1>
          <Shapes type="11" color="var(--bs-info)"></Shapes>  
          <Shapes type="10" color="var(--bs-hippie-blue)"></Shapes>  
          <Shapes type="12" color="var(--bs-primary)"></Shapes>  
        </div>
        <p>No te pierdas del Ferial 2024, “Un viaje al corazón 2: Nuestros municipios." ❤️  en el Teatro Aguascalientes.</p>
        <p class="mb-4"><b>Venta de boletos a partir del lunes 1 de abril.</b></p>
        <Ferial></Ferial>
      </div>
    </section>
   
    <section class="section bg-primary" id="convocatorias">
      <div class="container">
        <div class="title-shapes mb-5">
          <Shapes type="10" color="#08cbbc"></Shapes>
          <Shapes type="11" color="#FFBC45"></Shapes>
          <h1 class="text-white text-center text-uppercase">Convocatorias</h1>
          <Shapes type="11" color="#FFBC45"></Shapes>  
          <Shapes type="10" color="#08cbbc"></Shapes>  
        </div>
        <Convocatorias></Convocatorias>
      </div>
    </section>

    <section class="section bg-purple">
      <div class="container">
        <div class="title-shapes mb-5">
          <Shapes type="12" color="#c85885"></Shapes>
          <Shapes type="10" color="#FFBC45"></Shapes>
          <Shapes type="11" color="#6b7cae"></Shapes>
          <h1 class="text-white text-center text-uppercase">Vive y Conoce</h1>
          <Shapes type="11" color="#6b7cae"></Shapes>  
          <Shapes type="10" color="#FFBC45"></Shapes>  
          <Shapes type="12" color="#c85885"></Shapes>  
        </div>
        
        <iframe class="video" src="https://www.youtube.com/embed/MswH_ZhVXvw?si=7Zbz2ZtMVX3uwP67" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        
      </div>
    </section>

    <section class="section bg-white sponsors">
      <div class="container">
        <div class="title-shapes mb-5">
          <Shapes type="12" color="#c85885"></Shapes>
          <Shapes type="10" color="#6b7cae"></Shapes>
          <Shapes type="11" color="#5598ac"></Shapes>
          <h1 class="text-center text-uppercase">Patrocinadores Oficiales</h1>
          <Shapes type="11" color="#5598ac"></Shapes>  
          <Shapes type="10" color="#6b7cae"></Shapes>  
          <Shapes type="12" color="#c85885"></Shapes>  
        </div>
        <div class="logos">
          <img v-for="patrocinador in patrocinadores" :key="patrocinador" :src="require('@/assets/img/2024/patrocinadores/' + patrocinador)" alt="" class="logo w-100">
        </div>
      </div>
    </section>


    

    <footer class="bg-dark footer m1kchy_a43dfr">
      <div class="container">
        <img src="@/assets/img/2024/logo_gea_patronato.svg" alt="">
        <img src="@/assets/img/2024/heraldica.svg" alt="">
        <img src="@/assets/img/2024/logo_viva_ags.svg" alt="">
      </div>
      
    </footer>

   <section class="bg-dark pb-3">
    <div class="container copy text-white text-center">
        Portal desarrollado por la <a href="https://www.aguascalientes.gob.mx/SIGOD/" target="_blank" class="text-white">Secretaría de Innovación y Gobierno Digital - SIGOD</a>
      </div>
   </section>



  </main>


</template>

<script>
import Programa from '../components/Programa.vue';
import Carteles from '../components/Carteles.vue';
import Convocatorias from '../components/Convocatorias.vue';
import Ferial from '../components/Ferial.vue';
import Mapas from '../components/Mapas.vue';
import Shapes from '../components/Shapes.vue';

export default {
  name: 'MiVista',
  data() {
    return {
      patrocinadores: [
        'corona.svg',
        'cruz-azul.svg',
        'nissan.svg',
        'rr.svg',
        'tecate.svg',
        'banorte.svg',
        'miaa.svg',
        'sigod.svg'
      ]
    };
  },
  components: {
    Programa, 
    Carteles, 
    Convocatorias, 
    Ferial,
    Mapas,
    Shapes,
  },
};
</script>